
import { computed, defineComponent, reactive } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { message, notification } from 'ant-design-vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { getSmsCaptcha } from '@/api/user/login';
import { postRegister } from '@/api/user/register';
import { AxiosError } from 'axios';
import { set_password_by_token } from '@/api/sys-model';
import {useRoute, useRouter} from 'vue-router';
import ls from "@/utils/local-storage";
import {HTTP_TOKEN} from "@/store/mutation-type";
import {useI18n} from "vue-i18n";

const levelNames: Record<number, string> = {
  0: '低',
  1: '低',
  2: '中',
  3: '强',
};
const levelClass: Record<number, string> = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success',
};
const levelColor: Record<number, string> = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a',
};

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    ls.set(HTTP_TOKEN, route.params.token);
    const state = reactive({
      time: 60,
      smsSendBtn: false,
      passwordLevel: 0,
      passwordLevelChecked: false,
      percent: 10,
      progressColor: '#FF0000',
      registerBtn: false,
    });
    const modelRef = reactive({
      email: route.params.username,
      password: '',
      password2: '',
      mobile: '',
      captcha: '',
    });
    const rulesRef = reactive({
      password: [
        { required: true, message: t('page.login.Please_newpassword') },
      ],
      password2: [
        { required: true, message: t('page.login.Please_input_a_password') },
        {
          validator: (rule: any, value: string) => {
            return new Promise((resolve, reject) => {
              const password = modelRef.password;
              if (value === undefined) {
                reject(new Error(t('page.login.Please_input_a_password')));
              }
              if (value && password && value.trim() !== password.trim()) {
                reject(new Error(t('page.login.The_two_passwords_are_inconsistent')));
              }
              resolve(null);
            });
          },
        },
      ],
    });
    const { validateInfos, validate } = useForm(modelRef, rulesRef);

    const handlePasswordInputClick = () => {
      state.passwordLevelChecked = true;
    };
    const handleSubmit = (e: Event) => {
      state.registerBtn = true;
      e.preventDefault();
      validate()
        .then(values => {
          set_password_by_token({
            newpassword: values.password,
          })
            .then(res => {
              state.registerBtn = false;
              message.success(t('page.login.Password_updated_successfully'));
              router.push({
                name: 'login',
              });
            }).finally(() => {
            state.registerBtn = false;
          })
        })
        .catch(err => {
          console.warn('err', err);
          state.registerBtn = false;
        })
    };
    return {
      t,
      state,
      modelRef,
      validateInfos,
      handleSubmit,
      handlePasswordInputClick,
    };
  },
  components: {
    MailOutlined,
  },
});
